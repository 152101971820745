<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div class="input-container width600">
        <input
          ref="password1"
          v-model="password1"
          v-on:keyup.enter="$refs.password2.focus()"
          required
          :type="passwordType"
          class="input-standard-white validation fadeIn-2"
          id="password1"
          placeholder="Choose password"
        />
        <label for="password1">Choose password</label>
        <span
          class="fa fa-fw fa-eye password-view-icon toggle-password link"
          @click="changeLoginPasswordType"
        ></span>
        <ErrorPop
          v-show="registerErrors.password1"
          :message="registerErrors.password1"
          @clearError="registerErrors.password1 = null"
        ></ErrorPop>
      </div>
      <div class="input-container width600 mb60">
        <input
          ref="password2"
          v-on:keyup.enter="show.agreementModal = true"
          v-model="password2"
          required
          :type="passwordType"
          class="input-standard-white validation fadeIn-2"
          id="password2"
          placeholder="Confirm password"
        />
        <label for="password2">Confirm password</label>
        <span
          class="fa fa-fw fa-eye password-view-icon toggle-password link"
          @click="changeLoginPasswordType"
        ></span>
        <ErrorPop
          v-show="registerErrors.password2"
          :message="registerErrors.password2"
          @clearError="registerErrors.password2 = null"
        ></ErrorPop>
      </div>

      <div class="input-container width600">
        <div class="row terms-holder">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div id="terms-label" class="pb30 css-checkbox light">
              <input
                @click="show.agreementModal = true"
                v-model="terms_and_conditions"
                type="checkbox"
                id="register-terms"
              />
              <label for="register-terms"
                >I agree to the
                <a @click="show.agreementModal = true" href="#"
                  >User Agreement</a
                ><span></span
              ></label>
            </div>
            <ErrorPop
              v-show="registerErrors.terms_and_conditions"
              :message="registerErrors.terms_and_conditions"
              @clearError="registerErrors.terms_and_conditions = null"
            ></ErrorPop>
          </div>
        </div>
      </div>
      <UserAgreementModal
        v-show="show.agreementModal"
        @close="decline"
        @accept="accept"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:backLink="backTo"
        v-bind:center="false"
        @forwardClick="registerUser"
        v-bind:loading="loading"
        v-bind:forwardDisabled="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import ErrorPop from '../../../../common/ui/ErrorPop'
import UserAgreementModal from '../../Login/UserAgreementModal'
import { http } from '@/services'

export default {
  name: 'ProfilePassword',
  components: {
    NavQuestion,
    NavQuestionButtons,
    ErrorPop,
    UserAgreementModal
  },
  mounted() {
    this.$emit('progress', '98%')
  },
  computed: {
    background() {
      return 'bg-personal-details-cookie'
    },
    isValidated() {
      return !!(
        this.password1 === this.password2 &&
        this.password1 &&
        this.terms_and_conditions
      )
    },
    number() {
      if (
        this.personalDetails.partner_full_name &&
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '13'
      if (this.personalDetails.partner_full_name) return '11'
      return '9'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    partner() {
      return this.$store.getters.partner
    },
    heading() {
      return 'Please choose a password...'
    },
    subHeading() {
      return (
        'A secure user area will be created for you to complete the questions and view your Will. You can access ' +
        'this area using your email address and a password (your password needs to be at least 10 characters long and ' +
        'include at least 1 number).'
      )
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillPassword'
    },
    backTo() {
      return '/write_my_will/username'
    }
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.full_name) this.name = value.full_name
        else if (this.personalDetails && this.personalDetails.partner_full_name)
          this.name = this.personalDetails.partner_full_name
      }
    }
  },
  data() {
    return {
      password1: null,
      password2: null,
      passwordType: 'password',
      terms_and_conditions: false,
      login: {
        username: null,
        password: null
      },
      registerErrors: {
        password1: null,
        password2: null,
        terms_and_conditions: null
      },
      show: {
        agreementModal: false
      },
      loading: false
    }
  },
  methods: {
    async signIn() {
      this.loading = true
      await this.$store
        .dispatch('obtainToken', {
          email: this.login.username,
          password: this.login.password,
          noRedirect: true
        })
        .then(() => {
          var data = {
            personal_details: this.$store.getters.personalDetails,
            coupon: this.$store.getters.checkoutBasket.coupon
          }
          http
            .post('wills/api/personal_details_from_profile', data)
            .then((response) => {
              this.$router.push('/home').catch((error) => {
                console.log(error)
              })
              this.loading = false
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
            })
        })
    },

    registerUser() {
      if (!this.loading) {
        this.loading = true
        var data = {
          email: this.$store.getters.personalDetails.username,
          password1: this.password1,
          password2: this.password2,
          terms_and_conditions: this.terms_and_conditions
        }
        this.$store.commit('personalDetails', {
          terms_and_conditions: true
        })
        http
          .post('/wills/api/register', data)
          .then((response) => {
            this.login.username = this.$store.getters.personalDetails.username
            this.login.password = this.password1
            this.signIn()
            this.$notification.success('User Created - Welcome to MyLastWill')
          })
          .catch((error) => {
            this.errorHandler(error.response.data)
            this.loading = false
          })
      }
    },
    changeLoginPasswordType() {
      if (this.passwordType === 'text') {
        this.passwordType = 'password'
      } else {
        this.passwordType = 'text'
      }
    },
    errorHandler(error) {
      if (error.email) {
        this.$store.commit('personalDetails', {
          usernameError: error.email[0]
        })
        this.$router.push({ name: 'WriteMyWillUsername' }).catch((error) => {
          console.log(error)
        })
      }
      if (error.password1) this.registerErrors.password1 = error.password1[0]
      if (error.password2) this.registerErrors.password2 = error.password2[0]
      if (error.terms_and_conditions)
        this.registerErrors.terms_and_conditions = error.terms_and_conditions[0]
    },
    accept() {
      this.terms_and_conditions = true
      this.show.agreementModal = false
    },
    decline() {
      this.show.agreementModal = false
      this.terms_and_conditions = false
    }
  }
}
</script>

<style scoped>
#terms-label label {
  text-decoration: none;
  font-size: 1.2em;
}
#terms-label label a {
  color: #e56781;
}
</style>
